module controllers {
    export module customs {

        interface IRefundClaimCreateScope extends ng.IScope { }

        interface IRefundClaiomCreateParams extends ng.ui.IStateParamsService {
            refId: number;
        }

        export class refundClaimCreateCtrl {

            static $inject = [
                "$scope",
                "$stateParams",
                "$rootScope",
                "customsService",
                "classificationValueService",
                "$timeout",
                "generalService",
                "$q",
                "$state",
                "entityService",
                "refundClaimService",
                'countryService',
                'refundClaimTypeService',
                "statusService",
                "$uibModal",
                "bsLoadingOverlayService",
                "counterService"
            ];

            public refId: number;
            loadPromises: ng.IPromise<any>[];
            ownerEntityId: number;
            refundClaimTypeId: number;
            refundClaimCounterId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;
            refundClaimCounterList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            Defer: ng.IDeferred<any>;
            customNumberCheck: boolean = false;
            customNumber: string;
            disableGoodsDecTab: boolean = true;
            NextStepButton: string = "Next";
            selectedTab: number = 0;
            ShowGoodsDecGrid: boolean = false;
            ShowPreviousButton: boolean = false;
            ShowNextButton: boolean = true;
            IsLoading: boolean = false;
            apiGoodDecList: uiGrid.IGridApi;
            refundClaimType: interfaces.applicationcore.IDropdownModel;
            refundClaimCounter: interfaces.applicationcore.IDropdownModel;
            goodsDecList: interfaces.customs.IRefundGoodsDeclarationDisplay[] = [];
            refundClaim: interfaces.customs.IRefundClaim;


            constructor(
                private $scope: IRefundClaimCreateScope,
                private $stateParams: IRefundClaiomCreateParams,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private customsService: interfaces.customs.ICustomsService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private refundClaimService: interfaces.customs.IRefundClaimService,
                private countryService: interfaces.applicationcore.ICountryService,
                private refundClaimTypeService: services.tariff.refundClaimTypeService,
                private statusService: interfaces.master.IStatusService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private counterService: interfaces.master.ICounterService) {

                //Get the ID to get the Refund Claim Details.
                this.refId = $stateParams.refId;

                //Get Entities Data
                this.loadPromises = [];

                this.loadPromises.push(this.loadEntities());

                if (this.refId > 0) {
                    this.loadPromises.push(this.loadRefundClaim());
                }

                

                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises).then((data) => {
                        // We can load the Goods Dec list if we have a valid redundClaim 

                        if (this.refId > 0) {
                            this.NextStep()
                        }
                    });
                });

            }

            //Loading Entities
            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    if (this.refId  <= 0) {
                        this.ownerEntityId = this.$rootScope.Entity.Id;
                    }
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }


            loadRefundTypes() {
                return this.refundClaimTypeService.getForDropDownByEntity(this.ownerEntityId).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Reload everything for the new Entity
            ownerEntityChanged() {
                this.refundClaimTypeId = 0;
            }

            //Loading Refund Claim Counters
            loadRefundClaimCounter() {

                return this.counterService.getDropdownList().query({
                    ownerEntityId: this.ownerEntityId,
                    counterTypeId: Enum.EnumCounterTypes.DutyDrawback,
                    isInbound: false},() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;

            }

            loadRefundClaim() {

                        return this.refundClaimService.getRefundClaimDetail().get({
                            refId: this.refId,  isClaim: false
                        }, (result: interfaces.customs.IRefundClaim) => {
                            this.refundClaim = result;

                            this.refundClaimTypeId = this.refundClaim.RefundClaimType.Id;
                            this.refundClaimType = this.refundClaim.RefundClaimType;
                            this.ownerEntityId = this.refundClaim.OwnerEntity.Id;
                            this.customNumberCheck = true;
                            this.customNumber = this.refundClaim.ReferenceNumber;

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                 
            }

            LoadGoodsDec()
            {
                this.IsLoading = true;
                return this.refundClaimService.getAvailableGoodsDeclarations().query(
                    {
                        ownerEntityId: this.ownerEntityId,
                        refundClaimTypeId: this.refundClaimType.Id
                    }, (result) => {
                        this.gvwGoodsDec.data = result;
                        this.IsLoading = false;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        this.IsLoading = false;
                    }).$promise;
            }

            NextStep() {
                this.selectedTab++;

                if (this.selectedTab == 1) {

                    this.disableGoodsDecTab = false;
                    this.ShowGoodsDecGrid = true;
                    this.ShowPreviousButton = true;
                    this.ShowNextButton = false;
                    this.LoadGoodsDec();
                }
            }

            PreviousStep() {
                this.selectedTab--;
                if (this.selectedTab == 0) {

                    this.disableGoodsDecTab = true;
                    this.ShowGoodsDecGrid = false;
                    this.ShowPreviousButton = false;
                    this.ShowNextButton = true;
                }
            }


            //Navigation
            close() {
               this.$state.go("^");
            }

            submit() {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    if (this.refId === 0) {
                        return this.CreateRefundClaim();

                    } 
                    else {
                        return this.UpdateRefundClaim();
                    }
                });

            }

            UpdateRefundClaim() {

                this.goodsDecList = this.apiGoodDecList.selection.getSelectedRows();


                var goodsDecSelectedList: interfaces.customs.IGoodsDecList = {
                    dutyDrawbackClaimToGoodsDecList: []
                };

                goodsDecSelectedList.dutyDrawbackClaimToGoodsDecList = this.goodsDecList;

                return this.refundClaimService.UpdateRefundClaim(
                   this.refId

                ).save(goodsDecSelectedList, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                        this.$state.go("^", { refId: Number(this.refId) });
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);
                    this.IsLoading = false;
                }).$promise;

            }

            CreateRefundClaim() {

                this.goodsDecList = this.apiGoodDecList.selection.getSelectedRows();

                var goodsDecSelectedList: interfaces.customs.IGoodsDecList = {
                    dutyDrawbackClaimToGoodsDecList: []
                };

                goodsDecSelectedList.dutyDrawbackClaimToGoodsDecList = this.goodsDecList;


                return this.refundClaimService.CreateRefundClaim(
                    this.ownerEntityId,
                    this.refundClaimType.Id,
                    this.refundClaimCounter ? this.refundClaimCounter.Id : undefined,
                    this.customNumber ? this.customNumber : undefined

                ).save(goodsDecSelectedList, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                            this.$state.go("auth.RefundClaim.Update", { refId: Number(result.ID) });
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);
                    this.IsLoading = false;
                    }).$promise;

            }


            registerGridApi(gridApi: uiGrid.IGridApi) {              
                this.apiGoodDecList = gridApi;           
            }
            
            gvwGoodsDec: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi);
                },
                columnDefs: [{
                    name: "ConsignmentNumber",
                    displayName: "Consignment Numbery",
                    field: "ConsignmentNumber",
                    type: "string",
                    width: 135,
                }, {
                    name: "CPCCode",
                    displayName: "CPC",
                    field: "CPCCode",
                    type: "string",
                    width: 75,
                }, {
                    name: "MRNNumber",
                    displayName: "MRN Number",
                    field: "MRNNumber",
                    type: "string",
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    width: 145,
                }, , {
                    name: "AssessmentDate",
                    displayName: "Assessment Date",
                    field: "AssessmentDate",
                    type: "string",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    width: 147,
                }, {
                    name: "Version",
                    displayName: "Version",
                    field: "Version",
                    width: 65,
                }, {
                    name: "FunctionType",
                    displayName: "Function",
                    field: "FunctionType",
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    type: "string",
                    width: 75,
                }, {
                    name: "Declarant",
                    displayName: "Declarant",
                    field: "Declarant",
                    type: "string",
                    width: 135,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "ImporterExporter",
                    displayName: "Importer Exporter",
                    field: "ImporterExporter",
                    type: "string",
                    width: 135,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }]
            };
        }

        angular.module("app").controller("refundClaimCreateCtrl", controllers.customs.refundClaimCreateCtrl);

    }
}